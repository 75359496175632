import FlashComponentComponent from '@wix/thunderbolt-elements/src/components/FlashComponent/viewer/FlashComponent';


const FlashComponent = {
  component: FlashComponentComponent
};


export const components = {
  ['FlashComponent']: FlashComponent
};


// temporary export
export const version = "1.0.0"
