import * as React from 'react';
import { ImageProps } from '@wix/thunderbolt-components-native';
import Link, { isValidLink } from '../../Link/viewer/Link';
import { IFlashComponentProps } from '../FlashComponent.types';
import Image from '../../Image/viewer/Image';
import styles from './style/FlashComponent.scss';

const FlashComponent: React.FC<IFlashComponentProps> = props => {
  const { id, link, uri, placeholderImage, onMouseEnter, onMouseLeave } = props;
  const [isImageFallback, setImageFallback] = React.useState(false);

  const handleCallback = (obj: any) => {
    if (!obj.success) {
      setImageFallback(true);
    }
  };

  React.useEffect(() => {
    const swfobject = (window as any).swfobject
      ? (window as any).swfobject
      : require('swfobject');
    if (uri && swfobject) {
      swfobject.embedSWF(
        uri,
        `flashObject_${id}`,
        '100%',
        '100%',
        '10.0.0',
        'playerProductInstall.swf',
        null,
        {
          quality: 'high',
          bgcolor: '#FAFAFA',
          allowscriptaccess: 'never',
          allowfullscreen: 'true',
          wMode: 'transparent',
          scale: 'exactFit',
          flashVars: '',
          play: 'true',
          autoplay: 'true',
        },
        { align: 'middle' },
        handleCallback,
      );
    }
  }, [uri, id]);

  // when there is a link in the props for the flash component,
  // and the flash object is working fine,
  // then we have to add an anchor that should work when the user clicks on it
  if (uri && !isImageFallback) {
    return (
      <div id={id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Link {...link}>
          <div id={`flashObject_${id}`} />
          {link && <div className={styles.flashClickOverlay} />}
        </Link>
      </div>
    );
  }
  const imageProps = {
    ...placeholderImage,
    containerId: id,
  } as ImageProps;
  const imageElement = <Image id={`image-${id}`} {...imageProps} />;
  if (isValidLink(link)) {
    // when there is a link in the props for the flash component,
    // but the flash object is not working for some reason,
    // then we have to add an anchor that should work
    return (
      <div id={id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Link {...link}>{imageElement}</Link>
      </div>
    );
  }
  // when there is no link and no working flash object
  // just show the placeholder/fallback image without link
  return (
    <div id={id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {imageElement}
    </div>
  );
};

export default FlashComponent;
